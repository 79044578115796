body, html {
  height: 100%;
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-layout {
  flex: 1;
  display: flex; /* Added to make main-layout a flex container */
  flex-direction: column;
  justify-content: center; /* Centers children vertically */
  align-items: center; /* Centers children horizontally */
  text-align: center;
}

.Footer {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
  flex-shrink: 0; /* Prevents the footer from shrinking */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px 0; /* Add padding to the top and bottom */
  min-height: 0; /* Remove min-height if not needed, or adjust accordingly */
}


.bodyBackground {
  background-image: url('./../public/wallpaper2.png'); /* Path to your image */
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  position: fixed; /* Makes the background fixed during scroll 
  height: 100vh; /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.timer-container {
    height: 80vh;
    position: absolute;
    left: 0;
    right: 0;
    overflow-y: auto;
  }
  
  .timer-container .card {
  }

  
  /* Adjust the height of text displays and button row if necessary */
  /* If you want to ensure they take up a certain percentage of the card's height, you might need to set the card's display to flex, flex-direction to column, and use flex properties on the children. */
  
  .custom-btn {
    border-radius: 50%;
    padding: 1rem;
    font-size: 1.5rem;
  }
  
  .btn-start {
    background-color: #4CAF50;
  }
  
  .btn-reset {
    background-color: #FF5722;
  }
  
  /* Ensure the huge-display and single-blind-display are aligned properly */
  .huge-display {
    font-size: 13rem;
  }
  
  .single-blind-display {
    font-size: 13rem;
  }
  